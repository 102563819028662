


























































































































import User from "@/utility/user";
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import Referral from "@/utility/referral";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";
import { sortItems } from "@/utils/sortingUtils";
import SubscriptionTier, {
  SubscriptionTierBillingType
} from "@/utility/subscriptionTier";
import { showErrorSnackbar } from "@/utils/snackbarUtils";

@Component({
  mixins: [DateFormatterMixin]
})
export default class ChangeUserSubscriptionPlanDialog extends Vue {
  // 🚩 Flag which determines whether the dialog should be shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The user which should have their subscription plan changed
  @Prop()
  public user!: User;

  // 📦 The referrals of the currently selected user
  private get referrals(): Referral[] {
    return this.user.referrals;
  }

  // 🚩 Flag for whether the dialog is expanded to a fullscreen
  private expanded: boolean = false;

  private items: string[] = ["init", "20-0", "10-10", "0-20"];

  private newReferralEmail: string | null = null;

  private newReferralTier: string = this.items[1];

  // 📦 The table headers
  private headers = [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Referral Tier", value: "referral_tier_id" },
    { text: "Exchanges", value: "exchanges" },
    { text: "Subscription Plan", value: "subscription_plan" },
    { text: "Active Bots", value: "number_of_active_bots" },
    { text: "Archived Bots", value: "number_of_archived_bots" },
    { text: "Balance In Use", value: "balance_in_use" },
    { text: "Created On", value: "account_creation_date" }
  ];

  /**
   * 📦 Default sorting by bot "created on" descending
   */
  private pagination = {
    sortBy: ["account_creation_date"],
    sortDesc: [false],
    mustSort: true,
    itemsPerPage: 15
  };

  // 📦 The number of referrals that have created at least one bot
  private get referralsWithAtLeastOneBot(): number {
    return this.referrals.filter(
      (referral: Referral) =>
        referral.number_of_active_bots + referral.number_of_archived_bots > 0
    ).length;
  }

  // 📦 The number of referrals that have activated a paid subscription
  private get referralsWithAPaidPlan(): number {
    const paidSubscriptionPlans = (this.$store.state.subscriptionTiers
      .subscriptionTiers as SubscriptionTier[])
      .filter(
        (tier: SubscriptionTier) =>
          tier.billingType !== SubscriptionTierBillingType.Free
      )
      .map((tier: SubscriptionTier) => tier.sub);

    return this.referrals.filter((referral: Referral) =>
      paidSubscriptionPlans.includes(referral.subscription_plan)
    ).length;
  }

  // 📦 The number of referrals that are using a free pro plan
  private get referralsWithAFreeProPlan(): number {
    const freeProPlans = (this.$store.state.subscriptionTiers
      .subscriptionTiers as SubscriptionTier[])
      .filter((tier: SubscriptionTier) => tier.freePro)
      .map((tier: SubscriptionTier) => tier.sub);

    return this.referrals.filter((referral: Referral) =>
      freeProPlans.includes(referral.subscription_plan)
    ).length;
  }

  /**
   * 🔨 Changes the referral tier of an existing referral of the current user
   */
  private changeReferralTier(referralEmail: string, newReferralTier: string) {
    // Override existing entries
    this.addNewReferral(referralEmail, newReferralTier, false);
  }

  /**
   * 🚀 Adds a new referral entry to the user's referrals
   */
  private addNewReferral(
    referralEmail: string,
    referralTier: string,
    newReferral: boolean = true
  ) {
    if (newReferral) {
      if (
        (this.$store.state.users.users as User[]).filter(
          (user: User) => user.email === referralEmail
        ).length == 0
      ) {
        showErrorSnackbar(
          "This user doesn't exist yet. Refresh the users' list and check it it exists"
        );
        return;
      }
    }

    Vue.prototype.$api
      .post("/api/proxy", "/api/v1/admin/referral", {
        referrer_username: this.user.email,
        referral_username: referralEmail,
        referral_tier_id: referralTier
      })
      .then((response: any) => {
        newReferral
          ? "Successfully added a new referral to " + this.user.email
          : "Successfully changed referral tier for " + referralEmail;
        this.$store.dispatch("fetchUsers");
      })
      .catch((error: any) =>
        newReferral
          ? "Failed when adding a new referral to " + this.user.email
          : "Failed when changing referral tier for " + referralEmail
      );
  }

  /**
   * 🔨 Custom sorting functionality used in the referrals table
   */
  private sortByCreationDate(items: any[], index: string[], isDesc: boolean[]) {
    return sortItems("account_creation_date", items, index, isDesc);
  }
}
